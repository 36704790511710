
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93nuk8U8yQzk42FLC78KbcEWoj19yIPfe30UjdPicRJRkMeta } from "/home/bun/app/pages/[...slug].vue?macro=true";
import { default as indexlUWVkei5p4jLAWMzjbwlfuv1Mgp0AR5x_45eE_bLrktL8Meta } from "/home/bun/app/pages/voix/menus/index.vue?macro=true";
import { default as indexBo95ptx5xhMB7bWxDsNkslAABKE3f53WYZ6YWPZdrVAMeta } from "/home/bun/app/pages/voix/posts/index.vue?macro=true";
import { default as index44olvp9WmA16yb55oUnPAFpPFxN_pzwzYeO3B7xXjcYMeta } from "/home/bun/app/pages/voix/brands/index.vue?macro=true";
import { default as indexGF9v9j_45rtM3viiM5rLlAIprM122qF2TI44ViBGZTSIcMeta } from "/home/bun/app/pages/voix/caches/index.vue?macro=true";
import { default as createRjujb25X3pEPOTG8XhuRUXvkRnbsoAPa_45lSbkPKXeyMMeta } from "/home/bun/app/pages/voix/menus/create.vue?macro=true";
import { default as createlPAaIkso0vrrYRKbK7q8vphzGr2D3UywNwGJHYE8nosMeta } from "/home/bun/app/pages/voix/posts/create.vue?macro=true";
import { default as createwpp3UNbdmDx0eENA2uk5VcAiIdQxo_3uQ8tGmm4MDREMeta } from "/home/bun/app/pages/voix/brands/create.vue?macro=true";
import { default as indexUb7ZiubajTIKCQIK4QCxT4cJxANql3cqkTyIabMWSH4Meta } from "/home/bun/app/pages/voix/resorts/index.vue?macro=true";
import { default as createFdVGwv70W1TU7VEzpYmEqoGxzSXjsFuB5ny7mRn_452DMMeta } from "/home/bun/app/pages/voix/resorts/create.vue?macro=true";
import { default as index3pfJLWwbAPWn8lVdMvwparpCvm_Ax4_45oz7SZvpzjuSUMeta } from "/home/bun/app/pages/voix/specials/index.vue?macro=true";
import { default as _91id_93kNATSnLQIPaj47hnLmctZAm0qUgEv5ogFE_45S21WRrj8Meta } from "/home/bun/app/pages/voix/menus/edit/[id].vue?macro=true";
import { default as _91id_932J25vob2CmQ6WUhRO9Ksq4rtdSXJUGp_0XB89n7RpcYMeta } from "/home/bun/app/pages/voix/posts/edit/[id].vue?macro=true";
import { default as createMRmPKo_45195PdGd_45UWAINylW_45ngwZhE0ZuNcDAu6KeuQMeta } from "/home/bun/app/pages/voix/specials/create.vue?macro=true";
import { default as _91id_93fz8icMufk4GYmB5a2EsvTDmcFutzURCrhWg2n6pdpQUMeta } from "/home/bun/app/pages/voix/brands/edit/[id].vue?macro=true";
import { default as indexE90e_qaPbOtaNIqKOcx1_45YwKiSYFPWOw2IxNHLpB80gMeta } from "/home/bun/app/pages/voix/fact-sheets/index.vue?macro=true";
import { default as _91id_93ABq_45GdRarSuesfmlDXnPlsUQNIeZtYRqLt_45dHsbzFsMMeta } from "/home/bun/app/pages/voix/resorts/edit/[id].vue?macro=true";
import { default as indexGC_LiF9qCHGS6dqzudwrWEbOdeVZEiIo6XDelbuhgHAMeta } from "/home/bun/app/pages/voix/style-guide/index.vue?macro=true";
import { default as creatembzj949fFFwQ_45l4qA6uJ6KW_eJ81ND24vcOGx3tze3sMeta } from "/home/bun/app/pages/voix/fact-sheets/create.vue?macro=true";
import { default as _91id_935k9eFwAerKC0LkkWe2wXg55skpvcNDj_BcgyxCK9vmkMeta } from "/home/bun/app/pages/voix/specials/copy/[id].vue?macro=true";
import { default as _91id_93Of7SuH6HdQvvgHDAlEY2cGk_45CChOx5guQU9ol_45K_hN4Meta } from "/home/bun/app/pages/voix/specials/edit/[id].vue?macro=true";
import { default as _91id_93GNDfnSGxzBZhL2ApQ_45etxXBXhXiqig5WJkJk3ePl0dgMeta } from "/home/bun/app/pages/voix/fact-sheets/edit/[id].vue?macro=true";
import { default as indexMvjIF6Ni_PsOZ3Z4y1uyp5f5ta4pDe33Af0vIckQFA4Meta } from "/home/bun/app/pages/voix/booking-code-triggers/index.vue?macro=true";
import { default as createD3JEPID7YrEMG1GpYTXYfIMLU5tgDlTxVN_0uJIl89kMeta } from "/home/bun/app/pages/voix/booking-code-triggers/create.vue?macro=true";
import { default as _91id_93y5gMkcxz_IbiDYqlVWkb7v8z6zbZRqMEu52y3eKQhPkMeta } from "/home/bun/app/pages/voix/booking-code-triggers/edit/[id].vue?macro=true";
import { default as voix_45debug8q7L7SdE7fIDIDxmWnJVQGaqbvxoYFCTJ45bSS_UDwMMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue?macro=true";
import { default as PreviewxomVcEvCkrNN0wIIUhmdPpwIJyA4vy6swHB6ldlXadQMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue?macro=true";
import { default as IndexmGF7j_4556Wz6V2m_455zE4OglmRXrzkfbWCdt_Hx1eWRnYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue?macro=true";
import { default as loginYP8MqdiO4yqS94PXpoowUi9PKy2eHXI8HjvZ_45UQSqQYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue?macro=true";
import { default as callbackYuzij_45oA1SlToE2fdToBSRY9_vfyw_Xw7H4PykbXlLcMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue?macro=true";
import { default as studioki1adt9RI4YsiC2p8Ul_Oa0STTG_wTnkQQujBZGrBPkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue?macro=true";
import { default as IndexpxWpcEedq_e2nNgD_45WGjRiUZ3u6h_45kENoE_38b4z9VAMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue?macro=true";
import { default as Editrc_YsoEXuOw9DuFyx3OWn_0zW20Bmgqq2l2QxoMLAuMMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue?macro=true";
import { default as Create_E5T4mvYwxomGoCUU0cgMlPa69IuvDxCcZ1HXu2FLgQMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue?macro=true";
import { default as IndexKmYAk8Hci7TCx7oqze2oJ4d17wCDrCmraDekCe4BrdkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue?macro=true";
import { default as CreatelaDfySlC8jd_v3kDoMFRiUBZDIGvyb_8Zo58bLtZQFMMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue?macro=true";
import { default as TranslatefwVBBach3L4ebRf9STdEq8mvh9rmPfIxkY9eYUgdD8QMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue?macro=true";
import { default as Copy8gvVEQqAhszTbeMqLXurzx1bbgCWXDr7Du28bHpRABYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue?macro=true";
import { default as IndexXjYlhPpoQDYAZp8NKNLBVRnWYUfjOYOSH2YZ_SjAGBoMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue?macro=true";
import { default as IndexnMQoayCWviv_o3Cxow5OvBoUU8hRwLuqQKmEnFSIwXEMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue?macro=true";
import { default as Edit5AiR7W8PzZeOKNCdGDLKFIRXyfegYpOTVyWPIEPiDKQMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue?macro=true";
import { default as CreateYhSnQMuE3JbAVwNsjnUSlfhw3OU95X_vakJPWfwcZrcMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue?macro=true";
import { default as Index65K8V_NGfA8OSqaqsWFMrTXPmWbt_453YbMzZPILiMn2sMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue?macro=true";
import { default as CreateP3XVRKzbmof_45SdlJ5BrCXD4NPR1PS_fd55I9PZBlbyUMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue?macro=true";
import { default as Edit7buWfTKiIZ7wCHsJzj3Mnso4rPT6o3IQ3R5dcr3_g4wMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue?macro=true";
import { default as ProfileIL576RecI2Iz_45pasGwNxZBkQavWtpZtGcOk2KD9VFu4Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue?macro=true";
import { default as indexU5XNT_xXKfH8D7UfenR6ZJbAUn6rwkNRRdcPh62xD2cMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue?macro=true";
import { default as SlicesViewskiGpEjJoS97kAr3I1PV6pnrs89GZqsGQaZ9HjO7K_45oMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicesView.vue?macro=true";
import { default as TemplateViewOgR21_w43w1jKza4YM_XkJFZxUPVFRFPx1NvF1fpUzkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/TemplateView.vue?macro=true";
import { default as DesignGuideViewAuQ74hzjBCk6SNn07BUhRfUKJ7XWH1y7QaPrrTtfcUsMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/DesignGuideView.vue?macro=true";
import { default as slicerULvJr9uRScpy4LRxn7OIzi7wgCTnOwCuZBxpKEq8D48Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/slicer.vue?macro=true";
import { default as documentAmt_45I7U2xDEBbgFplLDEanQ_2O_dhh8TRqqDkqiWoRYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/documentation/document.vue?macro=true";
import { default as voixTNiia8CC3clxw7303xzQIEItF22KOAGNNJeQ6W9X01oMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue?macro=true";
import { default as SlicePreviewD6CnH6ifRZwIKWYsLmH_FXS6rWJURTgYnQex7V8O2h4Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicePreview.vue?macro=true";
import { default as previewEVhvZhXT_45m9cIC9bf7VD3_N_45F4D_457PKb6mpyzWVHJIEMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue?macro=true";
export default [
  {
    name: "slug-en",
    path: "/:slug(.*)*",
    component: () => import("/home/bun/app/pages/[...slug].vue")
  },
  {
    name: "slug-es",
    path: "/es/:slug(.*)*",
    component: () => import("/home/bun/app/pages/[...slug].vue")
  },
  {
    name: "voix-menus-en",
    path: "/voix/menus",
    component: () => import("/home/bun/app/pages/voix/menus/index.vue")
  },
  {
    name: "voix-menus-es",
    path: "/es/voix/menus",
    component: () => import("/home/bun/app/pages/voix/menus/index.vue")
  },
  {
    name: "voix-posts-en",
    path: "/voix/posts",
    component: () => import("/home/bun/app/pages/voix/posts/index.vue")
  },
  {
    name: "voix-posts-es",
    path: "/es/voix/posts",
    component: () => import("/home/bun/app/pages/voix/posts/index.vue")
  },
  {
    name: "voix-brands-en",
    path: "/voix/brands",
    component: () => import("/home/bun/app/pages/voix/brands/index.vue")
  },
  {
    name: "voix-brands-es",
    path: "/es/voix/brands",
    component: () => import("/home/bun/app/pages/voix/brands/index.vue")
  },
  {
    name: "voix-caches-en",
    path: "/voix/caches",
    component: () => import("/home/bun/app/pages/voix/caches/index.vue")
  },
  {
    name: "voix-caches-es",
    path: "/es/voix/caches",
    component: () => import("/home/bun/app/pages/voix/caches/index.vue")
  },
  {
    name: "voix-menus-create-en",
    path: "/voix/menus/create",
    component: () => import("/home/bun/app/pages/voix/menus/create.vue")
  },
  {
    name: "voix-menus-create-es",
    path: "/es/voix/menus/create",
    component: () => import("/home/bun/app/pages/voix/menus/create.vue")
  },
  {
    name: "voix-posts-create-en",
    path: "/voix/posts/create",
    component: () => import("/home/bun/app/pages/voix/posts/create.vue")
  },
  {
    name: "voix-posts-create-es",
    path: "/es/voix/posts/create",
    component: () => import("/home/bun/app/pages/voix/posts/create.vue")
  },
  {
    name: "voix-brands-create-en",
    path: "/voix/brands/create",
    component: () => import("/home/bun/app/pages/voix/brands/create.vue")
  },
  {
    name: "voix-brands-create-es",
    path: "/es/voix/brands/create",
    component: () => import("/home/bun/app/pages/voix/brands/create.vue")
  },
  {
    name: "voix-resorts-en",
    path: "/voix/resorts",
    component: () => import("/home/bun/app/pages/voix/resorts/index.vue")
  },
  {
    name: "voix-resorts-es",
    path: "/es/voix/resorts",
    component: () => import("/home/bun/app/pages/voix/resorts/index.vue")
  },
  {
    name: "voix-resorts-create-en",
    path: "/voix/resorts/create",
    component: () => import("/home/bun/app/pages/voix/resorts/create.vue")
  },
  {
    name: "voix-resorts-create-es",
    path: "/es/voix/resorts/create",
    component: () => import("/home/bun/app/pages/voix/resorts/create.vue")
  },
  {
    name: "voix-specials-en",
    path: "/voix/specials",
    component: () => import("/home/bun/app/pages/voix/specials/index.vue")
  },
  {
    name: "voix-specials-es",
    path: "/es/voix/specials",
    component: () => import("/home/bun/app/pages/voix/specials/index.vue")
  },
  {
    name: "voix-menus-edit-id-en",
    path: "/voix/menus/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/menus/edit/[id].vue")
  },
  {
    name: "voix-menus-edit-id-es",
    path: "/es/voix/menus/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/menus/edit/[id].vue")
  },
  {
    name: "voix-posts-edit-id-en",
    path: "/voix/posts/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/posts/edit/[id].vue")
  },
  {
    name: "voix-posts-edit-id-es",
    path: "/es/voix/posts/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/posts/edit/[id].vue")
  },
  {
    name: "voix-specials-create-en",
    path: "/voix/specials/create",
    component: () => import("/home/bun/app/pages/voix/specials/create.vue")
  },
  {
    name: "voix-specials-create-es",
    path: "/es/voix/specials/create",
    component: () => import("/home/bun/app/pages/voix/specials/create.vue")
  },
  {
    name: "voix-brands-edit-id-en",
    path: "/voix/brands/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/brands/edit/[id].vue")
  },
  {
    name: "voix-brands-edit-id-es",
    path: "/es/voix/brands/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/brands/edit/[id].vue")
  },
  {
    name: "voix-fact-sheets-en",
    path: "/voix/fact-sheets",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/index.vue")
  },
  {
    name: "voix-fact-sheets-es",
    path: "/es/voix/fact-sheets",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/index.vue")
  },
  {
    name: "voix-resorts-edit-id-en",
    path: "/voix/resorts/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/resorts/edit/[id].vue")
  },
  {
    name: "voix-resorts-edit-id-es",
    path: "/es/voix/resorts/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/resorts/edit/[id].vue")
  },
  {
    name: "voix-style-guide-en",
    path: "/voix/style-guide",
    component: () => import("/home/bun/app/pages/voix/style-guide/index.vue")
  },
  {
    name: "voix-style-guide-es",
    path: "/es/voix/style-guide",
    component: () => import("/home/bun/app/pages/voix/style-guide/index.vue")
  },
  {
    name: "voix-fact-sheets-create-en",
    path: "/voix/fact-sheets/create",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/create.vue")
  },
  {
    name: "voix-fact-sheets-create-es",
    path: "/es/voix/fact-sheets/create",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/create.vue")
  },
  {
    name: "voix-specials-copy-id-en",
    path: "/voix/specials/copy/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/copy/[id].vue")
  },
  {
    name: "voix-specials-copy-id-es",
    path: "/es/voix/specials/copy/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/copy/[id].vue")
  },
  {
    name: "voix-specials-edit-id-en",
    path: "/voix/specials/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/edit/[id].vue")
  },
  {
    name: "voix-specials-edit-id-es",
    path: "/es/voix/specials/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/edit/[id].vue")
  },
  {
    name: "voix-fact-sheets-edit-id-en",
    path: "/voix/fact-sheets/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/edit/[id].vue")
  },
  {
    name: "voix-fact-sheets-edit-id-es",
    path: "/es/voix/fact-sheets/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/edit/[id].vue")
  },
  {
    name: "voix-booking-code-triggers-en",
    path: "/voix/booking-code-triggers",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/index.vue")
  },
  {
    name: "voix-booking-code-triggers-es",
    path: "/es/voix/booking-code-triggers",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/index.vue")
  },
  {
    name: "voix-booking-code-triggers-create-en",
    path: "/voix/booking-code-triggers/create",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/create.vue")
  },
  {
    name: "voix-booking-code-triggers-create-es",
    path: "/es/voix/booking-code-triggers/create",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/create.vue")
  },
  {
    name: "voix-booking-code-triggers-edit-id-en",
    path: "/voix/booking-code-triggers/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/edit/[id].vue")
  },
  {
    name: "voix-booking-code-triggers-edit-id-es",
    path: "/es/voix/booking-code-triggers/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/edit/[id].vue")
  },
  {
    name: "voix-debug",
    path: "/voix-debug",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue")
  },
  {
    name: "voix-preview-release-entry-en",
    path: "/preview-release/:releaseId/:previewSecret",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue")
  },
  {
    name: "voix",
    path: "/voix",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue"),
    children: [
  {
    name: "voix-settings-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue")
  },
  {
    name: "voix-login-en",
    path: "login",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue")
  },
  {
    name: "voix-auth-callback-en",
    path: "auth/callback",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue")
  },
  {
    name: "voix-studio-en",
    path: "studio",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue")
  },
  {
    name: "voix-settings-head-tags-en",
    path: "head-tags",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue")
  },
  {
    name: "voix-settings-head-tags-edit-en",
    path: "head-tags/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue")
  },
  {
    name: "voix-settings-head-tags-create-en",
    path: "head-tags/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue")
  },
  {
    name: "voix-settings-pages-en",
    path: "pages",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue")
  },
  {
    name: "voix-settings-pages-create-en",
    path: "pages/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue")
  },
  {
    name: "voix-settings-pages-translate-en",
    path: "pages/translate",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue")
  },
  {
    name: "voix-settings-pages-copy-en",
    path: "pages/copy",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue")
  },
  {
    name: "voix-settings-releases-en",
    path: "releases",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue")
  },
  {
    name: "voix-settings-redirects-en",
    path: "redirects",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue")
  },
  {
    name: "voix-settings-redirects-edit-en",
    path: "redirects/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue")
  },
  {
    name: "voix-settings-redirects-create-en",
    path: "redirects/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue")
  },
  {
    name: "voix-settings-users-en",
    path: "users",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue")
  },
  {
    name: "voix-settings-users-create-en",
    path: "users/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue")
  },
  {
    name: "voix-settings-users-edit-en",
    path: "users/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue")
  },
  {
    name: "voix-settings-users-profile-en",
    path: "profile",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue")
  },
  {
    name: "voix-cookbook-en",
    path: "cookbook",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue")
  },
  {
    name: "voix-slicer-en",
    path: "slicer",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/slicer.vue"),
    children: [
  {
    name: "voix-slicer-slices-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicesView.vue")
  },
  {
    name: "voix-slicer-template-en",
    path: "templates/:templateName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/TemplateView.vue")
  },
  {
    name: "voix-slicer-design-guide-en",
    path: "design-guide",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/DesignGuideView.vue")
  }
]
  },
  {
    name: "voix-documentation-en",
    path: "documentation/:documentPath(.*)?",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/documentation/document.vue")
  }
]
  },
  {
    name: "slicer-slice-en",
    path: "/voix/slice-preview/:sliceName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicePreview.vue")
  },
  {
    name: "cookbook-preview-en",
    path: "/voix/cookbook-preview/:recipe(.*)",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue")
  }
]